import React, { useState } from "react";
import img1 from '../assets/sqlbgg.jpg';
import axios from 'axios';  // If you want to use axios

function SQLForm() {
  const [formData, setFormData] = useState({
    hostName: "",
    username: "",
    password: "",
    databaseName: "",
  });

  // Get user email from sessionStorage
  function getUserEmail() {
    const keys = Object.keys(sessionStorage);
    for (const key of keys) {
      if (key.includes('login.windows.net')) {
        const data = JSON.parse(sessionStorage.getItem(key));
        if (data && data.username) {
          return data.username;
        }
      }
    }
    return null;
  }

  const userEmail = getUserEmail();

  // Handle input changes in the form
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async () => {
    const { hostName, username, password, databaseName } = formData;

    if (!userEmail) {
      alert("User email not found!");
      return;
    }

    const data = {
      email_id: userEmail,
      host: hostName,
      database: databaseName,
      user: username,
      password: password,
    };

    const backendUrl = process.env.REACT_APP_BACKEND_URL.replace(/\/$/, '');  // Remove any trailing slash from the backend URL

    console.log('Sending data:', data);
    console.log('API URL:', `${backendUrl}/save_connection`);

    try {
      // Sending POST request to FastAPI route
      const response = await axios.post(`${backendUrl}/save_connection`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.data.status === "success") {
        alert("Connection details saved successfully!");
      } else {
        alert("Error saving connection details!");
      }
    } catch (error) {
      console.error("Error during the API call:", error);
      alert("Failed to save connection details.");
    }
  };

  return (
    <div className="relative w-full h-screen bg-cover bg-center" style={{ backgroundImage: `url(${img1})` }}>
      <div className="absolute inset-0 bg-black opacity-40"></div>
      <div className="relative z-10 flex flex-col items-center justify-center w-full h-full text-indigo-500">
        <div className="bg-white rounded-lg shadow-lg p-5 w-11/12 sm:w-3/4 max-w-3xl h-5/6 sm:h-3/4 flex flex-col sm:flex-row relative overflow-y-auto">
          <div className="w-full sm:w-1/2 flex items-center justify-center mb-4 sm:mb-0">
            <dotlottie-player
              src="https://lottie.host/b3f92f36-0a88-443c-a352-413e157e3064/oyRMVcPWPp.json"
              background="transparent"
              border="2px solid black"
              speed="1"
              loop
              autoplay
              style={{ width: '100%', height: '100%' }}
            />
          </div>
          <div className="w-full sm:w-1/2 flex flex-col mt-4 mb-4">
            <h2 className="text-lg font-bold mb-4">Database Configuration</h2>
            <form>
              <div className="mb-4">
                <label className="block text-black text-sm font-bold mb-2">Host Name</label>
                <input
                  type="text"
                  name="hostName"
                  value={formData.hostName}
                  onChange={handleChange}
                  className="w-full border rounded p-2"
                  placeholder="Enter Host Name"
                />
              </div>
              <div className="mb-4">
                <label className="block text-black text-sm font-bold mb-2">Username</label>
                <input
                  type="text"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  className="w-full border rounded p-2"
                  placeholder="Enter Username"
                />
              </div>
              <div className="mb-4">
                <label className="block text-black text-sm font-bold mb-2">Password</label>
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  className="w-full border rounded p-2"
                  placeholder="Enter Password"
                />
              </div>
              <div className="mb-4">
                <label className="block text-black text-sm font-bold mb-2">Database Name</label>
                <input
                  type="text"
                  name="databaseName"
                  value={formData.databaseName}
                  onChange={handleChange}
                  className="w-full border rounded p-2"
                  placeholder="Enter Database Name"
                />
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  className="bg-indigo-500 text-white px-8 py-2 rounded"
                  onClick={handleSubmit} // Trigger form submission
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SQLForm;
