import React from 'react'
import Navbar from './Navbar';
import Options from './Options';
function Home() {
  return (
    <div>
        <Navbar/>
        <Options/>
    </div>
  )
}

export default Home