import React, { useState } from "react";

function DatabaseForm({ onClose }) {
  const [formData, setFormData] = useState({
    hostName: "",
    username: "",
    password: "",
    databaseName: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = () => {
    console.log("Form Data Submitted:", formData);
    onClose(); // Close the modal
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg shadow-lg w-96 p-6">
        <h2 className="text-lg font-bold mb-4">Database Configuration</h2>
        <form>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">Host Name</label>
            <input
              type="text"
              name="hostName"
              value={formData.hostName}
              onChange={handleChange}
              className="w-full border rounded p-2"
              placeholder="Enter Host Name"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">Username</label>
            <input
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
              className="w-full border rounded p-2"
              placeholder="Enter Username"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">Password</label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="w-full border rounded p-2"
              placeholder="Enter Password"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">Database Name</label>
            <input
              type="text"
              name="databaseName"
              value={formData.databaseName}
              onChange={handleChange}
              className="w-full border rounded p-2"
              placeholder="Enter Database Name"
            />
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="button"
              className="bg-blue-500 text-white px-4 py-2 rounded"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default DatabaseForm;
