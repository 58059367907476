import React, { useState } from "react";
import { ReactOneDriveFilePicker } from "react-onedrive-filepicker";
import image7 from "../assets/onedrive.png";
 
const KEY = process.env.REACT_APP_MS_CLIENTID;
 
export const FilePicker = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
 
  const handleSuccess = async (result) => {
    setIsLoading(false);
    setError(null);
 
    if (result?.value?.length > 0) {
      result.value.forEach(async (file) => {
        try {
          const fileName = file.name || "downloaded_file"; // Use the file's name or a fallback name
          const downloadUrl = file["@microsoft.graph.downloadUrl"];
 
          if (!downloadUrl) {
            throw new Error(`No download URL found for file: ${fileName}`);
          }
 
          const response = await fetch(downloadUrl);
          if (!response.ok) {
            throw new Error(`Failed to fetch file: ${fileName}`);
          }
 
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
 
          // Create a temporary anchor to trigger the download
          const link = document.createElement("a");
          link.href = url;
          link.download = fileName; // Set the correct file name
          document.body.appendChild(link);
          link.click();
          console.log(downloadUrl)
          // Clean up
          link.remove();
          window.URL.revokeObjectURL(url);
        } catch (err) {
          console.error("Error downloading file:", err);
          setError(`Failed to download file: ${file.name || "unknown"}`);
        }
      });
    }
  };
 
  const handleError = (err) => {
    setIsLoading(false);
    setError("Failed to fetch files. Please try again.");
    console.error("File Picker Error:", err);
  };
 
  const handleClick = () => {
    setIsLoading(true);
    setError(null);
  };
 
  return (
    <div style={{ position: "relative" }}>
      <div className="mainDiv">
        <ReactOneDriveFilePicker
          clientID={KEY}
          action="download" // Action to retrieve download URLs
          multiSelect={true}
          onSuccess={handleSuccess}
          onError={handleError}
        >
          <button
            onClick={handleClick}
            className="flex items-center justify-center p-2"
          >
            <img src={image7} alt="OneDrive Logo" className="h-8 w-8 mr-2" />
            {isLoading ? "Loading..." : "Upload Using OneDrive"}
          </button>
        </ReactOneDriveFilePicker>
      </div>
 
      {error && (
        <div className="mt-4 text-red-500">
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};