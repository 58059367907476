import React, { useState, useEffect } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import img1 from '../assets/modal.jpg';
import { MdDelete } from "react-icons/md";
import axios from 'axios';
import { toast } from 'react-toastify'; // Import toast and ToastContainer
import Loader from '../assets/chatloader.gif'


import '../Styles/ShowDb.css';

const ShowDb = ({ isOpen, onClose, databases , setDatabases }) => {

    function getUserEmail() {
        const keys = Object.keys(sessionStorage);
        for (const key of keys) {
            if (key.includes('login.windows.net')) {
                const data = JSON.parse(sessionStorage.getItem(key));
                if (data && data.username) {
                    return data.username;
                }
            }
        }
        return null;
    }


    const handleDeleteDatabase = async (db) => {
        const userEmail = getUserEmail();
        if (userEmail) {
            console.log(`User's email: ${userEmail}`);
            console.log(db)
        } else {
            console.log("Email not found in session storage.");
        }
        
        if (!userEmail) {
            toast.error("User email not found.");
            return;
        }

        try {
            // console.log('Params:', {
            //     userEmail: encodeURIComponent(userEmail),
            //     db_name: encodeURIComponent(db),
            // });
            setloader(true)
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL.replace(/\/$/, '')}/delete_database`,
               // `${process.env.REACT_APP_BACKEND_URL}/delete_database`,
                
                null,
                {
                    params: {
                        userEmail: userEmail,
                        db_name: db,
                    },
                }
            );
        
            if (response.status === 200) {
                toast.success(response.data.message || "Database record deleted successfully");
                setDatabases(prevDatabases => prevDatabases.filter(database => database !== db));

                // localStorage.setItem('databases',JSON.stringify(databases));


                // Optionally refresh data or remove the deleted item from the UI
            }
        } catch (error) {
            toast.error("Failed to delete database record.");
            console.error("Error deleting database:", error);
        }finally{
            setloader(false)
        }
    };

    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [loader, setloader] = useState(false);

    useEffect(() => {
        if (!isOpen) {
            setSearchTerm('');
            setLoading(true); // Reset loading state when modal closes
        } else {
            // Simulate fetching databases (replace with actual fetching logic)
            const fetchDatabases = async () => {
                // Simulate a delay
                await new Promise(resolve => setTimeout(resolve, 1000));
                setLoading(false);
            };

            fetchDatabases();
        }
    }, [isOpen]);

    const handleDbClick = async (db) => {
        try {
            navigate('/query', { state: { selectedDb: db, databases } });
        } catch (error) {
            console.error('Error querying database:', error);
        }
    };

    if (!isOpen) return null;

    const filteredDatabases = databases.filter(db =>
        db.toLowerCase().includes(searchTerm.toLowerCase())
    );



    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white rounded-lg shadow-lg p-5 w-11/12 sm:w-3/4 max-w-3xl h-5/6 sm:h-3/4 flex flex-col sm:flex-row  relative overflow-y-auto" style={{
                backgroundImage: `url(${img1})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
            }}>
                <button
                    className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
                    onClick={onClose}
                >
                    <AiOutlineClose size={24} />
                </button>

                <div className="w-full sm:w-1/2 flex items-center justify-center mb-4 sm:mb-0">
                    <dotlottie-player
                        src="https://lottie.host/df37380d-761e-4429-883c-274fd94b4134/LmtWU3WVXP.json"
                        background="transparent"
                        border="2px solid black"
                        speed="1"
                        loop
                        autoplay
                        style={{ width: '100%', height: '100%' }}
                    />
                </div>

                <div className="w-full sm:w-1/2 flex flex-col mt-4 mb-4">
                    <h2 className="text-xl font-bold mb-1 text-center text-indigo-500">Select Database</h2>
                    <p className="text-gray-700 text-center mb-4">Pick a Database and Let the Chat Begin!</p>

                    <input
                        type="text"
                        placeholder="Search databases..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="mb-4 p-2 border border-gray-300 rounded"
                    />
                    <ul className="max-h-[calc(100%_-_4rem)] custom-scrollbar overflow-y-auto flex-1">
                        {loading ? (
                            <li className="text-center py-4 font-bold">Processing...</li>
                        ) : databases.length === 0 ? (
                            <li className="text-center py-4">No databases created</li>
                        ) : filteredDatabases.length > 0 ? (
                            filteredDatabases.map((db, index) => (
                                <li
                                    key={index}
                                    className="py-2 px-2 border-b hover:bg-gray-300 hover:font-bold transition-colors duration-300 cursor-pointer flex justify-between items-center"
                                    onClick={() => handleDbClick(db)}
                                >
                                    <span>{db}</span>
                                    <MdDelete className="text-red-600 w-6 h-6"
                                        onClick={(e) => {
                                            e.stopPropagation(); // Prevent triggering the parent `onClick` event
                                            handleDeleteDatabase(db);
                                        }} /> {/* Add the icon here */}
                                </li>
                            ))
                        ) : (
                            <li className="text-center py-4 font-bold">No matching databases found</li>
                        )}
                    </ul>
                </div>
                {loader && (
                <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-80 z-50">
                    <div className="bg-white rounded-full">
                    <img src={Loader} alt="Loader" className="w-40 h-40 rounded-full" />
                    </div>
                </div>
        )}
            </div>
        </div>
    );
};

export default ShowDb;
