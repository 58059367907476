import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useMsal } from "@azure/msal-react"; // Import useMsal from MSAL React
import {
  FaMapMarkerAlt,
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaBars,
  FaTimes,
  FaYoutube,
} from "react-icons/fa";
import "../Styles/Navbar.css";
import { loginRequest } from "../authConfig"; // Import your MSAL login configuration

const Navbar = () => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const { instance } = useMsal(); // Use MSAL instance for authentication

  function getUserEmail() {
    // Retrieve all keys in session storage
    const keys = Object.keys(sessionStorage);

    // Loop through each key to find the relevant data
    for (const key of keys) {
      // Check if the key indicates it may contain user info, particularly the name
      if (key.includes('login.windows.net')) { // or any other relevant keyword
        // Parse the JSON string to get the data object
        const data = JSON.parse(sessionStorage.getItem(key));
        // Check if the data object contains a name field
        if (data && data.username) {
          return data.username; // Return the user's name
        }
      }
    }
    return null; // Return null if no name is found
  }

  // Usage
  // const userEmail = getUserEmail();
  // if (userEmail) {
  //   console.log(`User's email: ${userEmail}`);
  // } else {
  //   console.log("Email not found in session storage.");
  // }

  // Function to get the user's name from session storage
  function getUserName() {
    // Retrieve all keys in session storage
    const keys = Object.keys(sessionStorage);

    // Loop through each key to find the relevant data
    for (const key of keys) {
      // Check if the key indicates it may contain user info, particularly the name
      if (key.includes('login.windows.net')) { // or any other relevant keyword
        // Parse the JSON string to get the data object
        const data = JSON.parse(sessionStorage.getItem(key));
        // Check if the data object contains a name field
        if (data && data.name) {
          return data.name; // Return the user's name
        }
      }
    }
    return null; // Return null if no name is found
  }

  // Usage
  

  const handleClick = () => {

    // if (!userName || !userEmail) {
    //   console.error("User information is missing.");
    //   return;
    // }

    // Start login process using MSAL
    instance.loginPopup(loginRequest)
      .then((response) => {
        console.log("Login successful:", response);
        // Optionally, you can handle any post-login logic here, like storing the access token or user info
        sessionStorage.setItem('msalToken', response.accessToken);
        let userName = getUserName();
        if (userName) {
          console.log(`User's name: ${userName}`);
        } else {
          console.log("Name not found in session storage.");
        }
        // getUserName();
        let userEmail = getUserEmail();
        if (userEmail) {
          console.log(`User's email: ${userEmail}`);
        } else {
          console.log("Email not found in session storage.");
        }
        // getUserEmail();
         axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/login`, {
        name: userName,
        email: userEmail,
      })
        .then((response) => {
          console.log("API response:", response.data);
          
        })
        .catch((error) => {
          console.error("Error in API call:", error);
        });
        navigate('/tools');
      })
      .catch((error) => {
        console.error("Login failed:", error);
      });
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header>
      <nav className="main-nav">
        <div className="logo">
          <a href="https://datadiscover.ai/" rel="noopener noreferrer">
            DataDiscover.ai
          </a>
        </div>
        <div className="menu-icon" onClick={toggleMenu}>
          {menuOpen ? <FaTimes /> : <FaBars />}
        </div>
        <ul className={`nav-links ${menuOpen ? "active" : ""}`}>
          <li>
            <a href="#">Home</a>
          </li>
          <li>
            <a href="#about-section">About Us</a>
          </li>
          <li>
            <a href="#timeline-main">Steps</a>
          </li>
          <li>
            <a href="#why-choose-us">Why Choose Us</a>
          </li>
          <li>
            <a href="#faq-form-section">Contact</a>
          </li>
          {/* <li>
            <a href="#pricing">Pricing</a>
          </li> */}
        </ul>
        <button className="get-started-btn" onClick={handleClick}>
          Get Started
        </button>
      </nav>
    </header>
  );
};

export default Navbar;
